<div
  #preloader
  class="preloader absolute glowing-text top-0 left-0 bottom-0 right-0 bg-black z-[999999] flex-col items-center justify-center"
  [ngClass]="(isPreloaderVisibleOnce$ | async) ? 'flex' : 'hidden'"
  [@fadeOut]="(isPreloaderVisible$ | async) ? 'notFaded' : 'faded'"
  (@fadeOut.done)="onFadeOutDone($event)"
>
  <div class="flex flex-col items-center justify-center select-none">
    <div class="flex flex-col items-center">
      <img
        src="assets/img/logo/logo-cybertruck.svg"
        [width]="(isSmallScreen$ | async) ? 300 : 496"
        [height]="(isSmallScreen$ | async) ? 94 : 154"
        alt="Cybertruck"
      />
      <div class="font-bandwagon-regular mt-3 text-2xl md:text-4xl glowing-text">wraps</div>
    </div>
  </div>

  <div class="mt-9 md:mt-14 relative flex flex-col items-center justify-center">
    <div class="absolute z-10">
      <div class="italic font-avenir-heavy text-black tracking-[0.2em]" style="text-shadow: none">
        CONFIGURATOR
      </div>
    </div>
    <div
      wrCropped
      style="--bottom-right: 0.5em; --img: #2f2f2f; --b: transparent"
      class="w-60 h-11"
    ></div>
    <div
      wrCropped
      style="--bottom-right: 0.5em; --img: #fff"
      class="w-60 h-11 absolute top-0 left-0"
      [style.clipPath]="polygon"
    ></div>
  </div>

  <div
    class="d-flex mt-16 md:mt-9 flex-column justify-content-center align-items-center text-center"
  >
    <a href="https://wrapmate.com" target="_blank">
      <img src="assets/img/logo/Cybertruck-Logo-Export-NEW-Stacked.png" class="" width="220" height="94" alt="Cybertruck" />
    </a>
  </div>
</div>
