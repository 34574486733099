<div
  *ngIf="isSmallScreen$ | async; else elseBlock"
  class="flex flex-col px-4 justify-between items-center h-full bg-cover bg-no-repeat bg-center"
>
  <video
    playsinline
    autoplay
    muted
    loop
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true"
    class="fixed -z-10 w-screen h-screen object-cover top-0 left-0"
  >
  <source 
  src="assets/video/cybertruck_full_mobile.mp4" 
  type="video/mp4"
  playsinline
  autoplay
  muted
  loop
  oncanplay="this.play()"
  onloadedmetadata="this.muted = true" 
  />
    Your browser does not support the video tag.
  </video>
  <a [routerLink]="['/configurator']" class="gtm-btn gtm-btn-anchor">
    <div class="mt-28 flex flex-col items-center select-none">
      <img src="assets/img/logo/cybertruck-glitch-UPDATE.svg" class="" width="300" height="94" alt="Cybertruck" />
      <div class="font-bandwagon-regular mt-3 text-3xl glowing-text">wraps</div>
    </div>
  </a>
  <div class="mb-6 flex flex-col items-center">
    <a
      [routerLink]="['/configurator']"
      class="flex items-center h-12 px-8 mt-8 text-sm tracking-[.145rem] gtm-btn gtm-btn-anchor"
      wrCropped
      style="--bottom-right: 0.75rem; --img: transparent"
      >Design yours</a
    >
    <div class="flex flex-col items-center gap-x-8 text-[10px] mt-16">
      <a
      href="https://wrapmate.com"
      target="_blank"
      class="gtm-btn gtm-btn-anchor"
      >
      <img src="assets/img/logo/Cybertruck-Logo-Export-NEW.png" class="" width="220" height="94" alt="Wrapmate" />
      </a>
    </div>
    <div class="text-[11px] mt-14">
      not AFFILIATED WITH TESLA - 
      <a
      href="mailto:cybertruck@wrapmate.com?subject=Cybertruck%20Wraps%20%3D%20%F0%9F%9A%80%F0%9F%8C%99&amp;body=Hi%20Wrapmate%2C%0A%0AWe%20love%20your%20configurator%20and%20want%20to%20partner.%20Give%20me%20a%20call%20at%20___-___-____%0A-%20Elon"
      ><u>UNLESS THEY WANT TO BE :)</u></a></div>
  </div>
</div>

<!-- DESKTOP -->
<ng-template #elseBlock>
  <div
    class="flex flex-col px-9 justify-between items-center h-full bg-cover bg-no-repeat bg-center"
  >
    <video
      playsinline
      autoplay
      muted
      loop
      oncanplay="this.play()"
      onloadedmetadata="this.muted = true"
      class="fixed -z-10 w-screen h-screen object-cover top-0 left-0 landing-video-tablet"
    >
    <source 
    src="assets/video/cybertruck_full_tablet.mp4" 
    media="(min-width: 768px)"
    type="video/mp4"
    playsinline
    autoplay
    muted
    loop
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true" 
    />
      Your browser does not support the video tag.
    </video>
    <video
      playsinline
      autoplay
      muted
      loop
      oncanplay="this.play()"
      onloadedmetadata="this.muted = true"
      class="fixed -z-10 w-screen h-screen object-cover top-0 left-0 landing-video-desktop"
    >
    <source 
    src="assets/video/cybertruck_full.mp4" 
    type="video/mp4"
    playsinline
    autoplay
    muted
    loop
    oncanplay="this.play()"
    onloadedmetadata="this.muted = true" 
    />
      Your browser does not support the video tag.
    </video>
    <div class="flex flex-col items-center justify-center mt-[11rem] select-none">
      <a [routerLink]="['/configurator']" class="gtm-btn gtm-btn-anchor">
        <div class="flex flex-col items-center">
          <img src="assets/img/logo/logo-cybertruck.svg" width="496" height="154" alt="Cybertruck" />
          <div class="font-bandwagon-regular mt-3 text-4xl glowing-text">wraps</div>
        </div>
      </a>
      <a
        [routerLink]="['/configurator']"
        class="flex items-center h-12 px-8 mt-8 text-sm tracking-[.145rem] gtm-btn gtm-btn-anchor"
        wrCropped
        style="--bottom-right: 0.75rem; --img: transparent"
        >Design yours</a
      >
    </div>
    <div class="flex justify-between items-center text-s w-full">
      <div>not AFFILIATED WITH TESLA - 
        <a
        class="gtm-btn"
        href="mailto:cybertruck@wrapmate.com?subject=Cybertruck%20Wraps%20%3D%20%F0%9F%9A%80%F0%9F%8C%99&amp;body=Hi%20Wrapmate%2C%0A%0AWe%20love%20your%20configurator%20and%20want%20to%20partner.%20Give%20me%20a%20call%20at%20___-___-____%0A-%20Elon"
        ><u>UNLESS THEY WANT TO BE :)</u></a></div>

      <div class="flex items-end justify-between gap-2">
        <a
          href="https://wrapmate.com"
          target="_blank"
          class="gtm-btn gtm-btn-anchor"
        >
        <img src="assets/img/logo/Cybertruck-Logo-Export-NEW.png" class="" width="220" height="94" alt="Wrapmate" />
        </a>
      </div>
    </div>
  </div>
</ng-template>
